/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~bs-stepper/dist/css/bs-stepper.min.css';


.ng-select.custom .ng-select-container  {            
    border-radius: 0.35rem;
}

ng-select.required.ng-invalid.ng-touched .ng-select-container{
    border-color: #F55252;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ng-select.required.ng-invalid.ng-untouched .ng-select-container {
    border-color: #F55252;
}


.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }